import { Link } from "react-router-dom";
import { useEffect } from "react";

import styles from './contact.module.css';

export const Contact = () => {
    
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <>
            <section className={`page-title ${styles.background}`}>
                <div className="auto-container">
                    <div className="content-box">
                        <div className="title">
                            <h1>Контакти</h1>
                        </div>
                        <ul className="bread-crumb clearfix">
                            <li><Link to="/">Начало</Link></li>
                            <li className="shape"></li>
                            <li>За контакт с нас</li>
                        </ul>
                    </div>
                </div>
            </section>

            {/* <section className="google-map-section">
                <div className="map-inner">
                    <div className="google-map"
                        id="contact-google-map"
                        data-map-lat="40.712776"
                        data-map-lng="-74.005974"
                        data-icon-path="assets/images/icons/map-marker.png"
                        data-map-title="Brooklyn, New York, United Kingdom"
                        data-map-zoom="12"
                        data-markers='{
    "marker-1": [40.712776, -74.005974, "<h4>Branch Office</h4><p>77/99 New York</p>","assets/images/icons/map-marker.png"]
    }'>

                    </div>
                </div>
            </section> */}

            <section className="contact-style-two">
                <div className="outer-container">
                    <div className="auto-container">
                        <div className="row clearfix">
                            <div className="col-lg-6 col-md-12 col-sm-12 form-column">
                                <div className="inner-box">
                                    <div className="sec-title light">
                                        <p>Свържи се с нас</p>
                                        <div className="shape"></div>
                                    </div>
                                    <div className="info-box">
                                    <h3>За въпроси и записване на час:</h3>
                                    <ul className="info clearfix">
                                        {/* @*<li>
                                            <i className="fal fa-map-marker"></i>
                                            <p>Visit The Store</p>
                                            <h4>85 Twin City Towers Newyork, USA</h4>
                                        </li>*@ */}
                                        <li>
                                            <i className="fal fa-phone"></i>
                                            <h4><span>Телефон: </span><a href="tel:+359878770402">0878 77 04 02</a></h4>
                                        </li>
                                        <li>
                                            <i className="fal fa-phone"></i>
                                            <h4><span>За технически въпроси: </span><a href="tel:+359895065360">0895 06 53 60</a></h4>
                                        </li>
                                        <li>
                                            <i className="fal fa-envelope-open"></i>
                                            <h4><span>Имейл: </span><a href="mailto:info@claudi.bg">info@claudi.bg</a></h4>
                                        </li>
                                    </ul>
                                    {/* @*<ul className="social-links clearfix">
                                        <li><Link to="contact.html"><i className="fab fa-facebook-f"></i></Link></li>
                                        <li><Link to="contact.html"><i className="fab fa-twitter"></i></Link></li>
                                        <li><Link to="contact.html"><i className="fab fa-pinterest-p"></i></Link></li>
                                        <li><Link to="contact.html"><i className="fab fa-google-plus-g"></i></Link></li>
                                    </ul>*@ */}
                                </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 info-column">
                                <div className="info-box">
                                    <h3>Работно време</h3>
                                    <ul>
                                        <li >
                                            <p className={styles.listColor}>Понеделник - от 09:00 до 19:00</p>
                                        </li>
                                        <li>
                                            <p className={styles.listColor}>Вторник - от 09:00 до 19:00</p>
                                        </li>
                                        <li>
                                            <p className={styles.listColor}>Сряда - от 09:00 до 19:00</p>
                                        </li>
                                        <li>
                                            <p className={styles.listColor}>Четвъртък - от 09:00 до 19:00</p>
                                        </li>
                                        <li>
                                            <p className={styles.listColor}>Петък - от 09:00 до 19:00</p>
                                        </li>
                                        <li>
                                            <p className={styles.listColor}>Събота - от 09:00 до 17:00 - само за записване на час</p>
                                        </li>
                                    </ul>
                                    {/* @*<ul className="social-links clearfix">
                                        <li><Link to="contact.html"><i className="fab fa-facebook-f"></i></Link></li>
                                        <li><Link to="contact.html"><i className="fab fa-twitter"></i></Link></li>
                                        <li><Link to="contact.html"><i className="fab fa-pinterest-p"></i></Link></li>
                                        <li><Link to="contact.html"><i className="fab fa-google-plus-g"></i></Link></li>
                                    </ul>*@ */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};