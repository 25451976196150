import { Link } from "react-router-dom";

import styles from './colors.module.css';

export const Color = ({ product }) => {
    function refreshPage() {
        window.location.reload(true);
    }
    return (
        <div className="col-lg-3 col-md-6 col-sm-12 masonry-item small-column all shutters curtains blinds home_drcor">
            <div className="project-block-one">
                <div className="inner-box">
                    <figure className="image-box">
                        <img src={`/images/gallery/gallery-${product.pic}.jpg`} />
                    </figure>
                    <div className="content-box">
                        {/* <div className="view-btn"><Link to={`/Colors/Samples/${product.model}`}>+</Link></div> */}

                        <h3 onClick={refreshPage} className={styles.h3}><Link to={`/Colors/Samples/${product.model}`}>{product.title}</Link></h3>
                        <div className="text">
                            <ul>
                                <li onClick={refreshPage}><Link to={`/Colors/Samples/${product.model}`}>Цветове</Link></li>
                                <li>/</li>
                                <li onClick={refreshPage}><Link to="/Calculator">Калкулатор</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};