import {useEffect} from 'react';

export const Privacy = () => {
    
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }, []);
    return (
        <>
            <div className="container">
                <div className="base-box p-6 text-justify text-gray-600 dark:text-zinc-400">
                    <h1>Политика за поверителност</h1>

                    <p>
                        Политиката за поверителност е свързана с данните, подлежащи на персонално идентифициране, които се споделят
                        чрез поддържания от нас уеб сайт. Тя описва възможностите, правата и изборите, които имате във връзка с
                        личните данни, които съхраняваме. Това са имейл адреси, IP адреси, данни, които се събират при посещението
                        на уеб сайтове и др.
                    </p>
                    <p className="mt-4">
                        При създаването на Политиката за поверителност сме взели предвид принципите и изискванията на Общия
                        регламент за защита на личните данни (GDPR). Регламентът е приет от Европейския Съюз и влиза в сила на
                        25.05.2018 г.
                    </p>
                    <p className="mt-4">
                        Чрез Общия регламент (GDPR) се гарантира по-добра защита на неприкосновеността на личния ни живот. Той
                        представлява набор от принципи и правила за Европейския Съюз, гарантиращи правна сигурност за фирмите и
                        защита на данните на гражданите. Регламентът дава възможност за по-големи права за гражданите, например
                        правото на информация за целите на обработване на лични данни, категориите обработвани лични данни,
                        трансферът на данни към други компании и трети страни, правото на достъп до данни, правото „да бъдеш
                        забравен“ и др.
                    </p>

                    <h4 className="mt-6 uppercase font-bold text-gray-800 dark:text-zinc-300">Какви данни събираме и обработваме?</h4>
                    <p className="px-4 mt-4">
                        Данните, които събираме при регистрация, използваме за предоставяне на услугите, предвидени в Общите условия
                        на сайта, в който сте се регистрирали – да публикувате и да комуникирате с останалите потребители.
                    </p>
                    <p className="px-4 mt-4">
                        Данните, които събираме от всички потребители, използваме и за постигане на по-добро качество и сигурност на
                        услугите ни, както и за избягване на зловредни действия. Например, за да избегнем присвояване на чужда
                        електронна идентичност, за да се предпазим от хакерски атаки и др.
                    </p>
                    <p className="px-4 mt-4">
                        Някои данни използваме за позициониране на контекстно препоръчано съдържание и контекстно таргетирана
                        реклама.
                    </p>
                    <p className="px-4 mt-4">
                        За целите на подбора на подходяща реклама, за независими статистически измервания и в някои други случаи,
                        даваме възможност и на трети страни да получават данни от нашите сайтове за поведението на потребителите
                        (напр. за реклама: Google, за статистика: Google Analytics). Тези трети страни са задължени да
                        спазват сходни политики за поверителност.
                    </p>

                    <h4 className="mt-6 uppercase font-bold text-gray-800 dark:text-zinc-300">Какви данни събираме за всички потребители?</h4>
                    <p className="px-4 mt-4">
                        В сайта, който поддържаме, събираме и съхраняваме информация за всички потребители за:
                    </p>
                    <p className="px-4 mt-4">
                        IP адрес (адрес по интернет протокол). Тези номера обикновено се задават в блокове по географски признак. IP
                        адресът често може да се използва за идентифициране на мястото, от което устройството се свързва към
                        интернет.
                    </p>
                    <p className="px-4 mt-4">Данни от инструменти за автоматично събиране на данни, като:</p>
                    <ul className="ml-8 mt-2 list-disc list-inside">
                        <li className="pb-2">Проследяване на секции в сайтовете, които посещавате;</li>
                        <li className="pb-2">Колко време отделяте в даден сайт;</li>
                        <li className="pb-2">В коя страница или статия сте влизали;</li>
                        <li className="pb-2">Рекламите, които сте видели или с които сте си взаимодействали;</li>
                        <li className="pb-2">Демографски данни и др.;</li>
                        <li className="pb-2">
                            При регистрация в claudi.bg се предоставят електронна поща, псевдоним и
                            парола. След регистрацията има възможност за въвеждане на име, дата на раждане и местожителство, като
                            тези данни не са задължителни.
                        </li>
                    </ul>

                    <h4 className="mt-6 uppercase font-bold text-gray-800 dark:text-zinc-300">На кого предоставяме вашите данни?</h4>
                    <p className="px-4 mt-4">
                        Данните, които ние събираме и съхраняваме при ползване на уеб сайта ни, не се предоставят на други лица.
                    </p>
                    <p className="px-4 mt-4">
                        Можем да предоставим информация, съхранявана при нас по причини от правен характер, ако достъпът,
                        употребата, запазването или разкриването й е правомерно изискано от държавни органи или длъжностни лица,
                        оправомощени със закон да изискват и събират информация, съдържаща лични данни и при спазване на нормативно
                        установения ред.
                    </p>


                    <h4 className="mt-6 uppercase font-bold text-gray-800 dark:text-zinc-300">Има ли възможност данните ви да попаднат в трети страни?</h4>
                    <p className="px-4 mt-4">
                        Действията ви в нашия сайт могат да доведат до автоматизирано предоставяне на данни на наши партньори за
                        осъществяване на рекламни и статистически цели. Това става посредством бисквитки или други уникални
                        идентификатори. Тези партньори, за да оперират с нас, спазват сходни политики за поверителност, базирани на
                        Общия регламент за защита на личните данни (GDPR). Без да е изчерпателен списъкът, такива са например:
                        Google със съответните свои продукти, Facebook със съответните продукти, Gemius и др.
                    </p>

                    <h4 className="mt-6 uppercase font-bold text-gray-800 dark:text-zinc-300">Какви инструменти за автоматично събиране на данни използваме?</h4>
                    <p className="px-4 mt-4">
                        Събираме данни и предоставяме такава възможност на трети страни, чрез използване на „бисквитки“.
                        „Бисквитката“ е малък файл, съдържащ поредица от знаци, който се поставя на компютъра Ви, когато посещавате
                        уебсайт. Когато го посетите отново, „бисквитката“ позволява на този сайт да разпознае вашия браузър. В
                        „бисквитките“ може да се съхраняват предпочитания на потребителя и друга информация. Често се използва в
                        комбинация с пикселен идентификатор. Бисквитките могат да са временни (сесийни) и постоянни.
                        <a href="https://bg.wikipedia.org/wiki/HTTP-%D0%B1%D0%B8%D1%81%D0%BA%D0%B2%D0%B8%D1%82%D0%BA%D0%B0" target="_blank" className="text-indigo-600 hover:underline dark:text-indigo-300">Прочети повече за бисквитките</a>
                    </p>


                    <h4 className="mt-6 uppercase font-bold text-gray-800 dark:text-zinc-300">За какво използваме бисквитки?</h4>
                    <p className="px-4 mt-4">
                        Използват се „бисквитки“ за настройки, функции и други услуги на сайта.
                    </p>
                    <p className="px-4 mt-4">
                        Използват се „бисквитки“ за удостоверяване на потребителите, за предотвратяване на измамническо използване
                        на идентификационни данни за вход, както и за защита на потребителската информация от неупълномощени страни.
                    </p>
                    <p className="px-4 mt-4">
                        Използват се „бисквитки“ за подобряване на работата на сайтовете и за предоставяне на очакваните от
                        посетителя услуги, като придвижване из уеб страниците или осъществяване на достъп до защитените части на
                        сайтовете или форумите. Без тези „бисквитки“ те не могат да функционират нормално.
                    </p>
                    <p className="px-4 mt-4">
                        Използват се „бисквитки“ за осигуряване на данни за системата ни за управление на реклама. Използват се и от
                        външни системи за управление на реклами, като тези на Google, Taboola и др. Някои от обичайните им
                        приложения са избиране на реклами въз основа на определени параметри, отчитането на ефективността на
                        кампаниите и избягване на показването на реклами, които потребител вече е виждал.
                    </p>
                    <p className="px-4 mt-4">
                        „Бисквитки“ събират информация за начина, по който потребителите взаимодействат със сайтовете. Това може да
                        включва страниците, които хората посещават най-често, и дали получават съобщения за грешка от някои от тях.
                        Използваме временни („сесийни“) „бисквитки“, за да усъвършенстваме услугите си, да подобрим сърфирането на
                        потребителите си и др. Блокирането или изтриването им няма да попречи на използването на съответния сайт.
                    </p>
                    <p className="px-4 mt-4">
                        Чрез „бисквитки“ се събират данни, позволяващи ни да разберем по-добре предпочитанията на потребителите си и
                        чрез проведения анализ да подобрим и развием услугите си. Обикновено се събира анонимна информация, без да
                        се идентифицират индивидуалните потребители или личните им данни. Много често, за целите на обективността и
                        сравнимостта на данните и аналитичните резултати, тези „бисквитки“ се поставят и/или следят от външни
                        компании. Ние използваме напр. Google Analytics.
                    </p>

                    <h4 className="mt-6 uppercase font-bold text-gray-800 dark:text-zinc-300"> Как да забраните "бисквитки"?</h4>
                    <p className="px-4 mt-4">
                        Ако не искате да разрешавате бисквитките изобщо или искате да използвате само определени бисквитки,
                        разгледайте настройките на браузъра си. Можете да ги използвате, за да оттеглите по всяко време вече дадено
                        съгласие за ползване, както и за да триете приети бисквитки.
                    </p>
                    <p className="px-4 mt-4">
                        Имайте предвид обаче, че чрез деактивиране на определени бисквитки може да бъде ограничен достъпът Ви до
                        някои функции на платформата.
                    </p>

                    <h4 className="mt-6 uppercase font-bold text-gray-800 dark:text-zinc-300">Как защитаваме вашите данни</h4>
                    <p className="px-4 mt-4">
                        С цел вашата сигурност, данните които се трансферират между вас и claudi.bg
                        се криптира посредством SSL. Извършваме прегледи на практиките си за събиране,
                        съхранение и обработка на информация, в това число физическите мерки за сигурност, с цел предотвратяването
                        на неразрешен достъп до системите. Това обаче не винаги е достатъчно за възпрепятстване на злонамерени атаки
                        и сривове.
                    </p>

                    <h4 className="mt-6 uppercase font-bold text-gray-800 dark:text-zinc-300">Колко време съхраняваме информацията?</h4>
                    <p className="px-4 mt-4">
                        За регистрираните потребители данните се съхраняват за постоянно. Всеки потребител има правото посредством
                        своя акаунт от меню "Профил" да изтрие профила си. По този начин всичките данни които съхраняваме за него
                        ще бъдат изтрити завинаги, освен ако потребителят не се регистрира отново.
                    </p>
                </div>
            </div>
        </>
    );
};