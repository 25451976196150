import { useEffect } from "react";

import styles from './gallery.module.css';
import { Picture } from './Picture';

import json from '../../tree/tree.json';

export const Gallery = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <>
            <section className={`page-title ${styles.backGroundPicture}`}>
                <div className="auto-container">
                    <div className="content-box">
                        <div className="title">
                            <h1>Галерия</h1>
                        </div>
                        <ul className="bread-crumb clearfix">
                            <li>Галерия</li>
                            <li className="shape"></li>
                            <li>Снимки</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="gallery-page-section sec-pad-2">
                <div className="auto-container">
                    <div className="sortable-masonry">
                        <div className="filters">
                            <ul className="filter-tabs filter-btns centred clearfix">
                                <li className="active filter" data-role="button" data-filter=".all">Всички</li>
                                {
                                    Object.keys(json.gallery)
                                        .filter(n => n != '_contents' && json.gallery[n]['_contents'].length != 0)
                                        .map(n => <li key={n} className="filter" data-role="button" data-filter={`.${n.substring(0, n.indexOf("_"))}`}>{`${n.substring(n.indexOf("_") + 1)}`}</li>)
                                }
                            </ul>
                        </div>
                        <div className="items-container row clearfix">
                            {
                                Object.keys(json.gallery)
                                    .filter(n => n != '_contents' && json.gallery[n]['_contents'].length != 0)
                                    .map(n =>
                                        json.gallery[n]._contents
                                            .map(j => <Picture key={j} group={{ title: n.substring(n.indexOf("_") + 1), folder: n, cssClass: n.substring(0, n.indexOf("_")), color: j }} />)
                                    )
                            }
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}; 