import { Link } from "react-router-dom";
import styles from './footer.module.css';

export const Footer = () => {
    return (
        <>
            <section className="feature-style-two bg-color-1">
                <div className={`pattern-layer ${styles.freeFeatures}`}></div>
                <div className="auto-container">
                    <div className="three-item-carousel owl-carousel owl-theme owl-dots-none owl-nav-none">
                        <div className="feature-block-two">
                            <div className="inner-box">
                                <Link to="/Contact"><div className="icon-box"><i className="icon-icon5"></i></div></Link>
                                <h3>Безплатна Консултация</h3>
                                <p>Безплатна консултация по телефона или във Вашия дом.</p>
                            </div>
                        </div>
                        <div className="feature-block-two">
                            <div className="inner-box">
                            <Link to="/Contact"><div className="icon-box"><i className="icon-icon4"></i></div></Link>
                                <h3>Безплатна доставка</h3>
                                <p>При нас доставката е безплатна за района на гр. София.</p>
                            </div>
                        </div>
                        <div className="feature-block-two">
                            <div className="inner-box">
                            <Link to="/Contact"><div className="icon-box"><i className="icon-icon6"></i></div></Link>
                                <h3>Безплатно оразмеряване</h3>
                                <p>Взимането на размер при нас е напълно безплатно.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <footer className="main-footer">
                <div className="footer-top">
                    <figure className="vector-image"><img src="~/images/resource/vector-image-4.png" alt="" /></figure>
                    <div className="auto-container">
                        <div className="widget-section">
                            <div className="row clearfix">
                                <div className="col-lg-3 col-md-6 col-sm-12 footer-column">
                                    <div className="footer-widget logo-widget">
                                        <div className="footer-logo">
                                            <figure className="logo"><Link to="/Home/Index"><img src="~/images/logo.png" alt="" /></Link></figure>
                                            <p>Щори, Сенници и Мрежи против насекоми</p>
                                        </div>
                                        <div className="text">
                                            <p>
                                                С формите и цветовете на нашите щори и продукти можете да експериментирате смело,
                                                доверявайки се на Вашата интуиция за красота и дизайн.
                                                Надяваме се домът Ви да стане по-пъстър и неповторим с нашите продукти и съдействие. Защото ние работим за Вас!
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12 footer-column">
                                    <div className="footer-widget links-widget ml-70">
                                        <div className="widget-title">
                                            <h3>Щори</h3>
                                            <div className="shape"></div>
                                        </div>
                                        <div className="widget-content">
                                            <ul className="links clearfix">
                                                <li><Link to="/Products/Details/Horizontal">Хоризонтални Щори</Link></li>
                                                <li><Link to="/Products/Details/Vertical">Вертикални Щори</Link></li>
                                                <li><Link to="/Products/Details/Wooden">Дървени Щори</Link></li>
                                                <li><Link to="/Products/Details/Roller">Руло Щори</Link></li>
                                                <li><Link to="/Products/Details/Roman">Римски Щори</Link></li>
                                                <li><Link to="/Products/Details/Pleat">Плисе Щори</Link></li>
                                                <li><Link to="/Products/Details/Bamboo">Бамбукови Щори</Link></li>
                                                <li><Link to="/Products/Details/ExternalRoller">Външни Ролетни Щори</Link></li>
                                                <li><Link to="/Products/Details/ExternalVenetian">Външни Ламелни Щори</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12 footer-column">
                                    <div className="footer-widget links-widget ml-100">
                                        <div className="widget-title">
                                            <h3>Сенници</h3>
                                            <div className="shape"></div>
                                        </div>
                                        <div className="widget-content">
                                            <ul className="links clearfix">
                                                <li><Link to="/Products/Details/Awning">Чупещо Рамо</Link></li>
                                                <li><Link to="/Products/Details/Awning">Падащо Рамо</Link></li>
                                                <li><Link to="/Products/Details/Awning">Балконски</Link></li>
                                                <li><Link to="/Products/Details/Awning">Кабрио</Link></li>
                                                <li><Link to="/Products/Details/Awning">Кристални Завеси</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12 footer-column">
                                    <div className="footer-widget links-widget ml-100">
                                        <div className="widget-title">
                                            <h3>Мрежи против насекоми</h3>
                                            <div className="shape"></div>
                                        </div>
                                        <div className="widget-content">
                                            <ul className="links clearfix">
                                                <li><Link to="/Products/Details/Nets">С панти</Link></li>
                                                <li><Link to="/Products/Details/Nets">Статични</Link></li>
                                                <li><Link to="/Products/Details/Nets">Ролетни</Link></li>
                                                <li><Link to="/Products/Details/Nets">Плисе</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="auto-container">
                        <div className="copyright centred">
                            <p>(&copy;) 2023. Всички права запазени.<Link to="/Privacy">Поверителност</Link> <Link to="/Cookies">Бисквитки</Link></p>
                            <p>  Powered by Anatoli Dimitrov</p>
                        </div>
                    </div>
                </div>
            </footer>
            <button className="scroll-top scroll-to-target" data-target="html">
                <span className="far fa-long-arrow-alt-up"></span>
            </button>
        </>
    );
};