import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import styles from './colors.module.css';
import { Sample } from './Sample';

import json from '../../tree/tree.json';

export const Samples = () => {

    let { modelId } = useParams();

    modelId = modelId.toLowerCase();

    const [products, setProducts] = useState({
        horizontal: { title: 'Хоризонтални Алуминиеви Щори' },
        vertical: { title: 'Вертикални Щори' },
        wooden: { title: 'Дървени Хоризонтални щори' },
        roller: { title: 'Руло Щори' },
        roman: { title: 'Римски Щори' },
        pleat: { title: 'Плисе Щори' },
        bamboo: { title: 'Бамбукови Щори' },
        externalroller: { title: 'Външни Ролетни Щори' },
        externalvenetian: { title: 'Външни Ламелни Щори' },
        awning: { title: 'Сенници' },
        nets: { title: 'Мрежи Против Насекоми' },
    });

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, [ modelId]);
    return (
        <>
            <section className={`page-title ${styles.backGroundPicture}`}>
                <div className="auto-container">
                    <div className="content-box">
                        <div className="title">
                            <h1>{products[modelId].title}</h1>
                        </div>
                        <ul className="bread-crumb clearfix">
                            <li>Мостри</li>
                            <li className="shape"></li>
                            <li>Цветове</li>
                        </ul>
                    </div>
                </div>
            </section>

            <div className="alert alert-danger alert-dismissible" role="alert">
                <p className="text-center">Изборът на цвят само от тази интернет страница не се препоръчва. Възможно е снимките да имат разлика с реалните мостри, поради дигитализцията на снимките и различните монитори.</p>
            </div>

            <section className="gallery-page-section sec-pad-2">
                <div className="auto-container">
                    <div className="sortable-masonry">
                        <div className="filters">
                            <ul className="filter-tabs filter-btns centred clearfix">
                                <li className="active filter" data-role="button" data-filter=".all">Всички</li>
                                {

                                    // <li className="active filter" data-role="button" data-filter=".all">Всички</li>
                                    Object.keys(json.samples[modelId])
                                        .filter(n => n != '_contents')
                                        .map(n => <li key={n} className="filter" data-role="button" data-filter={`.${n.substring(0, n.indexOf("_"))}`}>{`${n.substring(n.indexOf("_") + 1)}`}</li>)
                                }
                            </ul>
                        </div>
                        <div className="items-container row clearfix">
                            {modelId != 'vertical'
                                ? Object.keys(json.samples[modelId])
                                    .filter(n => n != '_contents')
                                    .map(n =>
                                        json.samples[modelId][n]._contents
                                            .map(j => <Sample key={j} group={{ title: n.substring(n.indexOf("_") + 1), product: modelId, folder: n, cssClass: n.substring(0, n.indexOf("_")), color: j }} />)
                                    )
                                : Object.keys(json.samples[modelId])
                                    .filter(n => n != '_contents')
                                    .map(n =>
                                        Object.keys(json.samples[modelId][n])
                                            .filter(j => j != '_contents')
                                            .map(j => json.samples[modelId][n][j]._contents
                                                    .map(k => <Sample key={k} group={{ title: n.substring(n.indexOf("_") + 1), product: modelId, folder: `${n}/${j}`, cssClass: n.substring(0, n.indexOf("_")), color: k }} />)
                                            )
                                    )

                            }
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}; 