import { useEffect, useState } from "react";

import styles from './colors.module.css';
import { Color } from "./Color";

export const Colors = () => {

    const [products, setProducts] = useState([
        { title: 'Хоризонтални Щори', model: 'Horizontal', pic: 8 },
        { title: 'Вертикални Щори', model: 'Vertical', pic: 9 },
        { title: 'Дървени щори', model: 'Wooden', pic: 10 },
        { title: 'Руло Щори', model: 'Roller', pic: 11 },
        { title: 'Плисе Щори', model: 'Pleat', pic: 14 },
        { title: 'Римски Щори', model: 'Roman', pic: 12 },
        { title: 'Бамбукови Щори', model: 'Bamboo', pic: 13 },
        { title: 'Външни Ролетни Щори', model: 'ExternalRoller', pic: 15 },
        { title: 'Външни Ламелни Щори', model: 'ExternalVenetian', pic: 16 },
        { title: 'Сенници', model: 'Awning', pic: 17 },
        { title: 'Мрежи Против Насекоми', model: 'Nets', pic: 18 },
    ]);

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);
    return (
        <>
            <section className={`page-title ${styles.backGroundPicture}`}>
                <div className="auto-container">
                    <div className="content-box">
                        <div className="title">
                            <h1>Мостри</h1>
                        </div>
                        <ul className="bread-crumb clearfix">
                            <li>Мостри</li>
                            <li className="shape"></li>
                            <li>Цветове</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="gallery-page-section sec-pad-2">
                <div className="auto-container">
                    <div className="sortable-masonry">
                        <div className="filters">
                        </div>
                        <div className="items-container row clearfix">

                            {products.map(i => <Color key={i.pic} product={i} />)}

                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}; 