export const Price = ({ group }) => {

    return (
        <>
            <div className={`col-lg-3 col-md-6 col-sm-12 masonry-item small-column all shutters ${group.cssClass}`}>
                <div className="project-block-one">
                    <div className="inner-box">
                        <figure className="image-box">
                            <img src={process.env.PUBLIC_URL + `/storage/catalog/${group.product}/${group.folder}/${group.color}`} />
                        </figure>
                        <div className="content-box">
                            {/* <div className="view-btn"><a href={process.env.PUBLIC_URL + `/storage/catalog/${group.product}/${group.folder}/${group.color}`} className="lightbox-image" data-fancybox="gallery">+</a></div> */}
                            <div className="text">
                                <a href={process.env.PUBLIC_URL + `/storage/catalog/${group.product}/${group.folder}/${group.color}`} className="lightbox-image" data-fancybox="gallery"><h3 className="purpleColor">{group.title}</h3></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};