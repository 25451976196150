import { Link } from "react-router-dom";
import { useEffect } from "react";
import styles from './home.module.css';

export const Error404 = () => {
    function refreshPage() {
        window.location.reload(true);
    }

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);
    return (
        <>
            <section className={`page-title ${styles.pageTitle}`}>
            <div className="auto-container">
                <div className="content-box">
                    <div className="title">
                        <h1>404</h1>
                    </div>
                    <ul className="bread-crumb clearfix">
                        <li onClick={refreshPage}><Link to="/">Начало</Link></li>
                        <li className="shape"></li>
                        <li>Грешка</li>
                    </ul>
                </div>
            </div>
        </section>

        <section className="error-section centred sec-pad">
            <div className="auto-container">
                <div className="inner-box">
                    <h1>404</h1>
                    <h2>Упс! Страницата не беше намерена!</h2>
                    <p>Моля върнете се <Link to="/">към началната страница</Link></p>
                    <div className="btn-box" onClick={refreshPage}>
                        <Link to="/" className="theme-btn-one">Начална страница</Link>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
};