import { Link } from "react-router-dom";
import styles from './products.module.css';


export const ServiceDetails = () => {


    function refreshPage() {
        window.location.reload(true);
    }

    return (
        <>
            <div className="sidebar-widget category-widget">
                <div className="widget-title">
                    <h3>Експерти в дизайна</h3>
                    <div className="shape"></div>
                </div>
                <div className="widget-content">
                    <ul className="category-list clearfix">
                        <li>
                            <h5><Link to="/Products/Details/Horizontal">Хоризонтални Щори</Link></h5>
                            <span className="line"></span>
                        </li>
                        <li>
                            <h5><Link to="/Products/Details/Vertical">Вертикални Щори</Link></h5>
                            <span className="line"></span>
                        </li>
                        <li>
                            <h5><Link to="/Products/Details/Wooden">Дървени Щори</Link></h5>
                            <span className="line"></span>
                        </li>
                        <li>
                            <h5><Link to="/Products/Details/Roller">Руло Щори</Link></h5>
                            <span className="line"></span>
                        </li>
                        <li>
                            <h5><Link to="/Products/Details/Roman">Римски Щори</Link></h5>
                            <span className="line"></span>
                        </li>
                        <li>
                            <h5><Link to="/Products/Details/Pleat">Плисе Щори</Link></h5>
                            <span className="line"></span>
                        </li>
                        <li>
                            <h5><Link to="/Products/Details/Bamboo">Бамбукови Щори</Link></h5>
                            <span className="line"></span>
                        </li>
                        <li>
                            <h5><Link to="/Products/Details/ExternalRoller">Външни Ролетни Щори</Link></h5>
                            <span className="line"></span>
                        </li>
                        <li>
                            <h5><Link to="/Products/Details/ExternalVenetian">Външни Ламелни Щори</Link></h5>
                            <span className="line"></span>
                        </li>
                        <li>
                            <h5><Link to="/Products/Details/Awning">Сенниции</Link></h5>
                            <span className="line"></span>
                        </li>
                        <li>
                            <h5><Link to="/Products/Details/Nets">Комарници</Link></h5>
                            <span className="line"></span>
                        </li>
                    </ul>
                </div>
            </div>
            <div onClick={refreshPage} className={`sidebar-gallery ${styles.sidebar1}`} >
                <div className="btn-box">
                    <Link to="/Gallery" className="theme-btn-one">Галерия</Link>
                </div>
            </div>
            <div className="sidebar-widget download-brochure">
                <div className="widget-title">
                    <h3>Допълнителна информация</h3>
                    <div className="shape"></div>
                </div>
                <div className="widget-content">
                    <ul className="brochure-list clearfix">
                        <li onClick={refreshPage}><h5><Link to="/Calculator">Калкулатор</Link></h5></li>
                        <li><h5><Link to="/Catalogs">Цени</Link></h5></li>
                        <li><h5><Link to="/Colors">Цветове</Link></h5></li>
                    </ul>
                </div>
            </div>
        </>
    );
};