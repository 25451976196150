import { Link } from "react-router-dom";
import styles from './home.module.css';
import { useEffect } from "react";

export const Home = () => {

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <>
            <section className="banner-section style-one">
                <div className="pattern-layer">
                    <div className="pattern-1"></div>
                    <div className="pattern-2"></div>
                    <div className="pattern-3"></div>
                    <div className="pattern-4"></div>
                </div>
                <div className="banner-carousel owl-theme owl-carousel owl-dots-none nav-style-one">
                    {/* UNCOMMENT ALOMST ALL COMMENTED CODE FOE CHRISTMAS AND EASTER */}
                    {/* <div className="slide-item">
                        <div className={`image-layer ${styles.banner0}`}></div>
                        <div className="content-box">
                            <h1> <br /></h1>
                            <div className="text">
                                <p>
                                    Уважаеми клиенти,

                                    във връзка с предстоящите коледни
                                    и новогодишни празници, с
                                    настоящото Ви уведомяваме, че в
                                    периода от 23 Декември 2023 до 03
                                    Януари 2024 включително
                                    фирма Клауди 2011 ЕООД
                                    няма да работи и да приема поръчки.

                                    С пожелания за весела коледа и
                                    щастлива нова година.
                                </p>
                                <div className="line"></div>
                            </div>
                        </div>
                    </div> */}
                    <div className="slide-item">
                        <div className={`image-layer ${styles.banner1}`}></div>
                        <div className="content-box">
                            <h1>Щори <br />Вътрешни и Външни</h1>
                            <div className="text">
                            {/* <p>
                                    Уважаеми клиенти,

                                    във връзка с предстоящите коледни
                                    и новогодишни празници, с
                                    настоящото Ви уведомяваме, че в
                                    периода от 23 Декември 2023 до 03
                                    Януари 2024 включително
                                    фирма Клауди 2011 ЕООД
                                    няма да работи и да приема поръчки.

                                    С пожелания за весела коледа и
                                    щастлива нова година.
                                </p> */}
                                <p>Разбери повече ...</p>
                                <div className="line"></div>
                                <Link to="/Products" className="theme-btn-one">Още</Link>
                            </div>
                        </div>
                    </div>
                    <div className="slide-item">
                        <div className={`image-layer ${styles.banner2}`}></div>
                        <div className="content-box">
                            <h1>Сенници <br />Всички Видове</h1>
                            <div className="text">
                            {/* <p>
                                    Уважаеми клиенти,

                                    във връзка с предстоящите коледни
                                    и новогодишни празници, с
                                    настоящото Ви уведомяваме, че в
                                    периода от 23 Декември 2023 до 03
                                    Януари 2024 включително
                                    фирма Клауди 2011 ЕООД
                                    няма да работи и да приема поръчки.

                                    С пожелания за весела коледа и
                                    щастлива нова година.
                                </p> */}
                                <p>Разбери повече ...</p>
                                <div className="line"></div>
                                <Link to="/Products/Details/Awning" className="theme-btn-one">Още</Link>
                            </div>
                        </div>
                    </div>
                    <div className="slide-item">
                        <div className={`image-layer ${styles.banner3}`}></div>
                        {/* style="background-image:url(/images/banner/banner-3.jpg)" */}
                        <div className="content-box">
                            <h1>Мрежи Против <br />Насекоми</h1>
                            <div className="text">
                            {/* <p>
                                    Уважаеми клиенти,

                                    във връзка с предстоящите коледни
                                    и новогодишни празници, с
                                    настоящото Ви уведомяваме, че в
                                    периода от 23 Декември 2023 до 03
                                    Януари 2024 включително
                                    фирма Клауди 2011 ЕООД
                                    няма да работи и да приема поръчки.

                                    С пожелания за весела коледа и
                                    щастлива нова година.
                                </p> */}
                                <p>Разбери повече ...</p>
                                <div className="line"></div>
                                <Link to="/Products/Details/Nets" className="theme-btn-one">Още</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="about-section">
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                            <div className="content_block_1">
                                <div className="content-box">
                                    <div className="sec-title">
                                        <p>Добре Дошли в Клауди Щори</p>
                                        <div className="shape"></div>
                                        <h2>Ние правим вашия дом свеж и модерен</h2>
                                    </div>
                                    <div className="text">
                                        <p>
                                            С повече от 10 годишна история фирма Клауди 2011 ЕООД  е един от лидерите на пазара за производство,
                                            продажба и монтаж на щори, сенници и мрежи против насекоми. Нашата продукция се отличава с прецизна изработка,
                                            високо качество, богат избор на цветове и решения и най-вече конкурентни цени.
                                        </p>
                                        <p>
                                            Екипът ни е съставен от амбицирани и компетентни специалисти, които са подготвени да отговорят на Вашите нужди,
                                            да помогнат при реализирането на Вашите идеи.
                                        </p>
                                    </div>
                                    <div className="inner-box">
                                        <figure className="vector-image"><img src="/images/resource/vector-image-1.png" alt="" /></figure>
                                        <div className="inner clearfix">
                                            <ul className="list-item">
                                                <li>Индивидуалност</li>
                                                <li>Високо качество</li>
                                            </ul>
                                            <ul className="list-item pl-45">
                                                <li>Богат избор от цветове</li>
                                                <li>Най-добрият избор</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                            <div className="image_block_1">
                                <div className="image-box ml-55">
                                    <figure className="vector-image rotate-me"><img src="/images/resource/vector-image-2.png" alt="" /></figure>
                                    <div className="image-pattern">
                                        <div className="pattern-1"></div>
                                        <div className="pattern-2"></div>
                                        <div className="pattern-3"></div>
                                        <div className="pattern-4"></div>
                                    </div>
                                    <figure className="image"><img src="/images/resource/about-1.jpg" alt="" /></figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};