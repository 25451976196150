import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import styles from './catalogs.module.css';
import { Price } from './Price';

import json from '../../tree/tree.json';

export const Prices = () => {

    let { catalogId } = useParams();

    function refreshPage() {
        window.location.reload(true);
    }


    // const [ refresh, setRefresh ] = useState(false);

    // if (!refresh.used) {
    //     setRefresh(refresh => true)
    //     refreshPage();
    // }

    catalogId = catalogId.toLowerCase();

    const [products, setProducts] = useState({
        horizontal: { title: 'Хоризонтални Алуминиеви Щори' },
        vertical: { title: 'Вертикални Щори' },
        wooden: { title: 'Дървени Хоризонтални щори' },
        roller: { title: 'Руло Щори' },
        roman: { title: 'Римски Щори' },
        pleat: { title: 'Плисе Щори' },
        bamboo: { title: 'Бамбукови Щори' },
        externalroller: { title: 'Външни Ролетни Щори' },
        externalvenetian: { title: 'Външни Ламелни Щори' },
        awning: { title: 'Сенници' },
        nets: { title: 'Мрежи Против Насекоми' },
    });

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);
    return (
        <>
            <section className={`page-title ${styles.backGroundPicture}`}>
                <div className="auto-container">
                    <div className="content-box">
                        <div className="title">
                            <h1>{products[catalogId].title}</h1>
                        </div>
                        <ul className="bread-crumb clearfix">
                            <li>Каталози</li>
                            <li className="shape"></li>
                            <li>Информация</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="gallery-page-section sec-pad-2">
                <div className="auto-container">
                    <div className="sortable-masonry">
                        <div className="filters">
                            <ul className="filter-tabs filter-btns centred clearfix">
                                <li className="active filter" data-role="button" data-filter=".all">Всички</li>
                                {

                                    // <li className="active filter" data-role="button" data-filter=".all">Всички</li>
                                    Object.keys(json.catalog[catalogId])
                                        .filter(n => n != '_contents')
                                        .map(n => <li key={n} className="filter" data-role="button" data-filter={`.${n.substring(0, n.indexOf("_"))}`}>{`${n.substring(n.indexOf("_") + 1)}`}</li>)
                                }
                            </ul>
                        </div>
                        <div className="items-container row clearfix">
                            {
                                Object.keys(json.catalog[catalogId])
                                    .filter(n => n != '_contents')
                                    .map(n =>
                                        json.catalog[catalogId][n]._contents
                                            .map(j => <Price key={j} group={{ title: n.substring(n.indexOf("_") + 1), product: catalogId, folder: n, cssClass: n.substring(0, n.indexOf("_")), color: j }} />)
                                    )
                            }
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}; 