import { useState, useEffect } from "react";
import styles from './calculator.module.css';

export const Calculator = () => {
    const [products, setProducts] = useState([
        'Хоризонтални Алуминиеви Щори',
        'Вертикални Щори',
        'Дървени Хоризонтални щори',
        'Руло Щори',
        'Римски Щори',
        'Бамбукови Щори',
        'Плисе Щори',
        'Външни Ролетни Щори',
        'Външни Ламелни Щори',
        'Сенници',
        'Мрежи Против Насекоми',
    ]);

    useEffect(() => {
      const head = document.querySelector("body");
      const script = document.createElement("script");

      script.setAttribute("type", 'module');
      script.setAttribute("src", '/js/calculator/calculator.js');
      head.appendChild(script);

      return () => {
        head.removeChild(script);
      };
    }, []);

    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    return (
        <>
            <div className="container">
                <div className="row"><h1>Калкулатор</h1></div>
                <div className="row">
                    <div id="calculator-container" className="col">
                        <div className="element">
                            <div className="question">
                                <h3>1. Продукт</h3><h4 id="typeHeading"></h4>
                                <button><i className="fas fa-minus-circle"></i></button>
                            </div>
                            <div id="productChoice" className="answer">
                                <fieldset id="product">
                                    {
                                        products.map((p, i) =>
                                            <div key={p} className="raduo-button-container">
                                                <input id={p} type="radio" product-name={p} name="type" value={i + 1} />
                                                <label className="radio-button-label" htmlFor={p}>{p}</label>
                                            </div>
                                        )
                                    }
                                </fieldset>
                            </div>
                        </div>

                        <div className="element">
                            <div className="question">
                                <h3>2. Модел </h3><h4 id="modelHeading"></h4>
                                <button><i className="fas fa-plus-circle"></i></button>
                            </div>
                            <div id="modelChoice" className="answer hideText">
                                <fieldset id="model">Изберете Продукт</fieldset>
                            </div>
                        </div>

                        <div className="element">
                            <div className="question">
                                <h3>3. Цвят</h3><h4 id="colorHeading"></h4>
                                <button><i className="fas fa-plus-circle"></i></button>
                            </div>
                            <div id="colorChoice" className="answer hideText">
                                <fieldset id="color">Изберете Модел</fieldset>
                            </div>
                        </div>

                        <div className="element">
                            <div className="question">
                                <h3>4. Размери, Екстри</h3>
                                <button><i className="fas fa-plus-circle"></i></button>
                            </div>
                            <div className="answer hideText">
                                <form id="input-form" className="default-form">
                                    Изберете Цвят
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <form id="result" className="default-form" method="post">
                        </form>
                    </div>
                    <div className="row margin-10px alert alert-success alert-dismissible" role="alert">
                        <h5>Цената от калкулатора включва:</h5>
                        <ul>
                            <li>
                                - изработката на прoдукта
                            </li>
                            <li>
                                - 6% търговска отстъпка
                            </li>
                            <li>
                                - вземане на размер(за района на София)
                            </li>
                            <li>
                                - доставка(за района на София)
                            </li>
                        </ul>
                    </div>


                    <div id="show-content" className="row hidden details-content-wrapper">
                        <div id="1" className=" details-content hide">
                            <h2>Как да премерим?</h2>
                            <p>
                                С метална ролетка измерете габаритните размери (ширина/ височина) на площта, която искате да бъде покрита
                                от щората (напр. от стъклодържател до стъклодържател на прозореца).<br />Нанесете размерите
                                на посочените места в калкулатора, изберете желаните екстри и натиснете бутона "Изчисли".<br />
                                Добавете цената за монтажа, която е от 6,00 лв./бр. щора (ако монтажа се извършва от наш техник). Към тази цена важат допълнителните 3%
                                отстъпка при предплащане.
                            </p>
                            <p><strong><span className={styles.red}>Ако имате затруднение не забравяйте, че вземането на размер, офертата на място и доставката са напълно безплатни!</span></strong></p>
                        </div>
                        <div id="2" className=" details-content hide">
                            <h2>Как да премерим?</h2>
                            <p>
                                С метална ролетка измерете габаритните размери (ширина/ височина) на площта,
                                която искате да бъде покрита от щората (напр. височина - от тавана до под перваза на прозореца и ширина например
                                по 15 см. от външната страна на левия и десния ръб на прозореца).
                                <br />Нанесете размерите
                                на посочените места в калкулатора изберете желаните екстри и натиснете бутона "Изчисли".<br />Добавете цената за монтажа, която е от
                                7,00 лв/л.м. на корниза (ако монтажа се извършва от наш техник). Към тази цена важат допълнителните 3% отстъпка при
                                предплащане.
                            </p>
                            <p><span className={styles.red}><strong>Ако имате затруднение не забравяйте, че вземането на размер, офертата на място и доставката са напълно безплатни!</strong></span></p>
                        </div>
                        <div id="3" className=" details-content hide">
                            <h2>Как да премерим?</h2>
                            <p>
                                С метална ролетка измерете габаритните размери (ширина/ височина) на площта,
                                която искате да бъде покрита от щората (напр. от стъклодържател до
                                стъклодържател на прозореца).<br />Нанесете размерите
                                на посочените места в калкулатора, изберете желаните екстри и натиснете бутона "Изчисли".<br />Добавете цената за монтажа, която е 7,00 лв/бр щора
                                (ако монтажа се извършва от наш техник). Към тази цена важат допълнителните
                                3% отстъпка при предплащане.
                            </p>
                            <p>
                                <span className={styles.red}>
                                    <strong>
                                        Ако имате затруднение не забравяйте, че
                                        вземането на размер, офертата на място и доставката са напълно
                                        безплатни!
                                    </strong>
                                </span>
                            </p>
                        </div>
                        <div id="4" className=" details-content hide">
                            <h2>Как да премерим?</h2>
                            <p>
                                С метална ролетка измерете габаритните размери (ширина/ височина) на площта, която искате да бъде покрита
                                от щората (напр. от стъклодържател до стъклодържател на прозореца).<br />Нанесете размерите
                                на посочените места в калкулатора, изберете желаните екстри и натиснете бутона "Изчисли".<br />
                                Добавете цената за монтажа, която е от 6,00 лв/бр щора (ако монтажа се извършва от наш техник).
                                Към тази цена важат допълнителните 3% отстъпка при предплащане.
                            </p>
                            <p><span className={styles.red}><strong>Ако имате затруднение не забравяйте, че вземането на размер, офертата на място и доставката са напълно безплатни!</strong></span></p>
                        </div>
                        <div id="5" className=" details-content hide">
                            <h2>Как да премерим?</h2>
                            <p>
                                С метална ролетка измерете габаритните размери (ширина/ височина) на площта, която искате да бъде покрита от щората
                                (напр. от стъклодържател до стъклодържател на прозореца).<br />
                                Нанесете размерите
                                на посочените места в калкулатора, изберете желаните екстри и натиснете бутона "Изчисли".<br />Добавете цената за монтажа, която е от 7,00 лв/бр щора (ако монтажа се извършва от наш техник).
                                Към тази цена важат допълнителните 3% отстъпка при предплащане.
                            </p>
                            <p><span className={styles.red}><strong>Ако имате затруднение не забравяйте, че вземането на размер, офертата на място и доставката са напълно безплатни!</strong></span></p>
                        </div>
                        <div id="6" className=" details-content hide">
                            <h2>Как да премерим?</h2>
                            <p>
                                С метална ролетка измерете габаритните размери (ширина/ височина) на площта,
                                която искате да бъде покрита от щората (напр. от стъклодържател до стъклодържател на прозореца).<br />
                                Нанесете размерите
                                на посочените места в калкулатора, изберете желаните екстри и натиснете бутона "Изчисли".<br />Добавете цената за монтажа, която е 8,00 лв/бр щора (ако монтажа се извършва от наш техник).
                                Към тази цена важат допълнителните 3% отстъпка при предплащане.
                            </p>
                            <p><span className={styles.red}><strong>Ако имате затруднение не забравяйте, че вземането на размер, офертата на място и доставката са напълно безплатни!</strong></span></p>
                        </div>
                        <div id="7" className=" details-content hide">
                            <h2>Как да премерим?</h2>
                            <p>
                                С метална ролетка измерете габаритните размери (ширина/ височина) на площта,
                                която искате да бъде покрита от щората (напр. от стъклодържател до стъклодържател на прозореца).<br />
                                Нанесете размерите
                                на посочените места в калкулатора, изберете желаните екстри и натиснете бутона "Изчисли".<br />Добавете цената за монтажа, която е 7,00 лв./бр. щора (ако монтажа се извършва от наш техник).
                                Към тази цена важат допълнителните 3% отстъпка при предплащане.
                            </p>
                            <p><span className={styles.red}><strong>Ако имате затруднение не забравяйте, че вземането на размер, офертата на място и доставката са напълно безплатни!</strong></span></p>
                        </div>
                        <div id="8" className=" details-content hide">
                            <h2>Как да премерим?</h2>
                            <p>
                                С метална ролетка измерете габаритните размери (ширина/ височина) на площта,
                                която искате да бъде покрита от щората (напр. от ръб до ръб на мазилката от външната страна на прозореца).<br />
                                Нанесете размерите
                                на посочените места в калкулатора, изберете желаните екстри и натиснете бутона "Изчисли".<br />
                                Добавете цената за монтажа, която е от 30,00 лв/бр. щора (ако монтажа се извършва от наш техник).
                                Към тази цена важат допълнителните 3% отстъпка при предплащане.
                            </p>
                            <p><span className={styles.red}><strong>Ако имате затруднение не забравяйте, че вземането на размер, офертата на място и доставката са напълно безплатни!</strong></span></p>
                        </div>
                        <div id="9" className=" details-content hide">
                            <h2>Как да премерим?</h2>
                            <p>
                                С метална ролетка измерете габаритните размери (ширина/ височина) на площта,
                                която искате да бъде покрита от щората (напр. от ръб до ръб на мазилката от външната страна на прозореца).<br />
                                Нанесете размерите
                                на посочените места в калкулатора, изберете желаните екстри и натиснете бутона "Изчисли".<br />
                                Добавете цената за монтажа, която e от 60,00 лв/бр щора (ако монтажа се извършва от наш техник).
                                Към тази цена важат допълнителните 3% отстъпка при предплащане.
                            </p>
                            <p><span className={styles.red}><strong>Ако имате затруднение не забравяйте, че вземането на размер, офертата на място и доставката са напълно безплатни!</strong></span></p>
                        </div>
                        <div id="10" className=" details-content hide">
                            <h2>Как да премерим?</h2>
                            <p>
                                С метална ролетка измерете ширината на мястото, където искате да се монтира сенника.
                                След това измерете колко сантиметра искате да излиза сенника напред (или сантиметрите, които искате да се спуска надолу за ветроупорните сенници).<br />
                                Нанесете размерите
                                на посочените места в калкулатора, изберете желаните екстри и натиснете бутона "Изчисли".
                                Добавете цената за монтажа, която e от 100,00 лв/бр. сенник (ако монтажа се извършва от наш техник).
                                Към тази цена важат допълнителните 3% отстъпка при предплащане.
                            </p>
                            <p><span className={styles.red}><strong>Ако имате затруднение не забравяйте, че вземането на размер, офертата на място и доставката са напълно безплатни!</strong></span></p>
                        </div>
                        <div id="11" className=" details-content hide">
                            <h2>Как да премерим?</h2>
                            <p>
                                С метална ролетка измерете ширината и височината на габарита на площта, която искате да се покрие от комарника.<br />
                                Нанесете размерите
                                на посочените места в калкулатора, изберете желаните екстри и натиснете бутона "Изчисли".<br />
                                Добавете цената за монтажа, която е от 10,00 лв./бр. (ако монтажа се извършва от наш техник). Към тази цена важат допълнителните 3% отстъпка при предплащане.
                            </p>
                            <p><span className={styles.red}><strong>Ако имате затруднение не забравяйте, че вземането на размер, офертата на място и доставката са напълно безплатни!</strong></span></p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}; 