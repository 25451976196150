import { Link } from "react-router-dom";

export const Header = () => {

    function refreshPage() {
        window.location.reload(true);
    }

    return (
        <>
            <header className="main-header style-one">
                <div className="header-lower">
                    <div className="outer-box clearfix">
                        <div onClick={refreshPage} className="logo-box pull-left">
                            <figure className="logo"  onClick={refreshPage}><Link to="/"><img src="/images/logo.png" alt="Claudi" /></Link></figure>
                        </div>
                        <div className="menu-area pull-right clearfix">
                            <div className="mobile-nav-toggler">
                                <i className="icon-bar"></i>
                                <i className="icon-bar"></i>
                                <i className="icon-bar"></i>
                            </div>
                            <nav className="main-menu navbar-light">
                                <div className="collapse navbar-collapse show clearfix custom" id="navbarSupportedContent">
                                    <ul className="navigation clearfix">
                                        <li onClick={refreshPage}>
                                            <Link to="/">Начало</Link>
                                        </li>
                                        <li className="dropdown">
                                            <Link to="/Catalogs">Цени</Link>
                                            <ul>
                                                <li className="dropdown">
                                                    <Link to="#">&lt;&lt; Щори</Link>
                                                    <ul>
                                                        <li onClick={refreshPage}><Link to="/Catalogs/Prices/Horizontal">Хоризонтални</Link></li>
                                                        <li onClick={refreshPage}><Link to="/Catalogs/Prices/Vertical">Вертикални</Link></li>
                                                        <li onClick={refreshPage}><Link to="/Catalogs/Prices/Wooden">Дървени</Link></li>
                                                        <li onClick={refreshPage}><Link to="/Catalogs/Prices/Roller">Руло</Link></li>
                                                        <li onClick={refreshPage}><Link to="/Catalogs/Prices/Roman">Римски</Link></li>
                                                        <li onClick={refreshPage}><Link to="/Catalogs/Prices/Pleat">Плисе</Link></li>
                                                        <li onClick={refreshPage}><Link to="/Catalogs/Prices/Bamboo">Бамбукови</Link></li>
                                                        <li onClick={refreshPage}><Link to="/Catalogs/Prices/ExternalRoller">Външни ролетни</Link></li>
                                                        <li onClick={refreshPage}><Link to="/Catalogs/Prices/ExternalVenetian">Външни ламелни</Link></li>
                                                    </ul>
                                                </li>
                                                <li onClick={refreshPage}><Link to="/Catalogs/Prices/Awning">Сенници</Link></li>
                                                <li onClick={refreshPage}><Link to="/Catalogs/Prices/Nets">Комарници</Link></li>
                                            </ul>
                                        </li>
                                        <li className="dropdown">
                                            <Link to="/Colors">Цветове</Link>
                                            <ul>
                                                <li className="dropdown">
                                                    <Link to="#">&lt;&lt; Щори</Link>
                                                    <ul>
                                                        <li onClick={refreshPage}><Link to="/Colors/Samples/Horizontal">Хоризонтални</Link></li>
                                                        <li onClick={refreshPage}><Link to="/Colors/Samples/Vertical">Вертикални</Link></li>
                                                        <li onClick={refreshPage}><Link to="/Colors/Samples/Wooden">Дървени</Link></li>
                                                        <li onClick={refreshPage}><Link to="/Colors/Samples/Roller">Руло</Link></li>
                                                        <li onClick={refreshPage}><Link to="/Colors/Samples/Roman">Римски</Link></li>
                                                        <li onClick={refreshPage}><Link to="/Colors/Samples/Pleat">Плисе</Link></li>
                                                        <li onClick={refreshPage}><Link to="/Colors/Samples/Bamboo">Бамбукови</Link></li>
                                                        <li><Link to="/Colors/Samples/ExternalRoller">Външни ролетни</Link></li>
                                                        <li><Link to="/Colors/Samples/ExternalVenetian">Външни ламелни</Link></li>
                                                    </ul>
                                                </li>
                                                <li onClick={refreshPage}><Link to="/Colors/Samples/Awning">Сенници</Link></li>
                                                <li><Link to="/Colors/Samples/Nets">Комарници</Link></li>
                                            </ul>
                                        </li>
                                        <li className="dropdown">
                                            <Link to="/Products">Продукти</Link>
                                            <ul>
                                                <li className="dropdown">
                                                    <Link to="#">&lt;&lt; Щори</Link>
                                                    <ul>
                                                        <li><Link to="/Products/Details/Horizontal">Хоризонтални</Link></li>
                                                        <li><Link to="/Products/Details/Vertical">Вертикални</Link></li>
                                                        <li><Link to="/Products/Details/Wooden">Дървени</Link></li>
                                                        <li><Link to="/Products/Details/Roller">Руло</Link></li>
                                                        <li><Link to="/Products/Details/Roman">Римски</Link></li>
                                                        <li><Link to="/Products/Details/Pleat">Плисе</Link></li>
                                                        <li><Link to="/Products/Details/Bamboo">Бамбукови</Link></li>
                                                        <li><Link to="/Products/Details/ExternalRoller">Външни ролетни</Link></li>
                                                        <li><Link to="/Products/Details/ExternalVenetian">Външни ламелни</Link></li>
                                                    </ul>
                                                </li>
                                                <li><Link to="/Products/Details/Awning">Сенници</Link></li>
                                                <li><Link to="/Products/Details/Nets">Комарници</Link></li>
                                            </ul>
                                        </li>
                                        <li onClick={refreshPage}>
                                            <Link to="/Calculator">Калкулатор</Link>
                                        </li>
                                        <li onClick={refreshPage}>
                                            <Link to="/Gallery">Галерия</Link>
                                        </li>
                                        <li>
                                            <Link to="/Contact">Контакти</Link>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                            <ul className="menu-right-content clearfix">
                                <li className="support-box">
                                    <i className="icon-phone"></i>
                                    <p>Безплатен Размер</p>
                                    <h3><Link to="tel:+359878770402">0878 77 04 02</Link></h3>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="sticky-header">
                    <div className="outer-box clearfix">
                        <div onClick={refreshPage} className="logo-box pull-left">
                            <figure className="logo"><Link to="/"><img src="/images/logo.png" alt="" /></Link></figure>
                        </div>
                        <div className="menu-area pull-right">
                            <nav className="main-menu clearfix">
                                <div className="collapse navbar-collapse show clearfix custom" id="navbarSupportedContent">
                                    <ul className="navigation clearfix">
                                        <li onClick={refreshPage}><Link to="/">Начало</Link></li>
                                        <li className="dropdown"><Link to="/Catalogs">Цени</Link>
                                            <ul>
                                                <li className="dropdown"><Link to="/">&lt;&lt; Щори</Link>
                                                    <ul>
                                                        <li onClick={refreshPage}><Link to="/Catalogs/Prices/Horizontal">Хоризонтални</Link></li>
                                                        <li onClick={refreshPage}><Link to="/Catalogs/Prices/Vertical">Вертикални</Link></li>
                                                        <li onClick={refreshPage}><Link to="/Catalogs/Prices/Wooden">Дървени</Link></li>
                                                        <li onClick={refreshPage}><Link to="/Catalogs/Prices/Roller">Руло</Link></li>
                                                        <li onClick={refreshPage}><Link to="/Catalogs/Prices/Roman">Римски</Link></li>
                                                        <li onClick={refreshPage}><Link to="/Catalogs/Prices/Pleat">Плисе</Link></li>
                                                        <li onClick={refreshPage}><Link to="/Catalogs/Prices/Bamboo">Бамбукови</Link></li>
                                                        <li onClick={refreshPage}><Link to="/Catalogs/Prices/ExternalRoller">Външни ролетни</Link></li>
                                                        <li onClick={refreshPage}><Link to="/Catalogs/Prices/ExternalVenetian">Външни ламелни</Link></li>
                                                    </ul>
                                                    <div className="dropdown-btn"><span className="fas fa-angle-down"></span></div>
                                                </li>
                                                <li onClick={refreshPage}><Link to="/Catalogs/Prices/Awning">Сенници</Link></li>
                                                <li onClick={refreshPage}><Link to="/Catalogs/Prices/Nets">Комарници</Link></li>
                                            </ul>
                                            <div className="dropdown-btn"><span className="fas fa-angle-down"></span></div>
                                        </li>
                                        <li className="dropdown"><Link to="/Colors">Цветове</Link>
                                            <ul>
                                                <li className="dropdown"><Link to="#">&lt;&lt; Щори</Link>
                                                    <ul>
                                                        <li onClick={refreshPage}><Link to="/Colors/Samples/Horizontal">Хоризонтални</Link></li>
                                                        <li onClick={refreshPage}><Link to="/Colors/Samples/Vertical">Вертикални</Link></li>
                                                        <li onClick={refreshPage}><Link to="/Colors/Samples/Wooden">Дървени</Link></li>
                                                        <li onClick={refreshPage}><Link to="/Colors/Samples/Roller">Руло</Link></li>
                                                        <li onClick={refreshPage}><Link to="/Colors/Samples/Roman">Римски</Link></li>
                                                        <li onClick={refreshPage}><Link to="/Colors/Samples/Pleat">Плисе</Link></li>
                                                        <li onClick={refreshPage}><Link to="/Colors/Samples/Bamboo">Бамбукови</Link></li>
                                                        <li><Link to="/Colors/Samples/ExternalRoller">Външни ролетни</Link></li>
                                                        <li><Link to="/Colors/Samples/ExternalVenetian">Външни ламелни</Link></li>
                                                    </ul>
                                                    <div className="dropdown-btn"><span className="fas fa-angle-down"></span></div>
                                                </li>
                                                <li onClick={refreshPage}><Link to="/Colors/Samples/Awning">Сенници</Link></li>
                                                <li><Link to="/Colors/Samples/Nets">Комарници</Link></li>
                                            </ul>
                                            <div className="dropdown-btn"><span className="fas fa-angle-down"></span></div>
                                        </li>
                                        <li className="dropdown"><Link to="/Products">Продукти</Link>
                                            <ul>
                                                <li className="dropdown"><Link to="#">&lt;&lt; Щори</Link>
                                                    <ul>
                                                        <li><Link to="/Products/Details/Horizontal">Хоризонтални</Link></li>
                                                        <li><Link to="/Products/Details/Vertical">Вертикални</Link></li>
                                                        <li><Link to="/Products/Details/Wooden">Дървени</Link></li>
                                                        <li><Link to="/Products/Details/Roller">Руло</Link></li>
                                                        <li><Link to="/Products/Details/Roman">Римски</Link></li>
                                                        <li><Link to="/Products/Details/Pleat">Плисе</Link></li>
                                                        <li><Link to="/Products/Details/Bamboo">Бамбукови</Link></li>
                                                        <li><Link to="/Products/Details/ExternalRoller">Външни ролетни</Link></li>
                                                        <li><Link to="/Products/Details/ExternalVenetian">Външни ламелни</Link></li>
                                                    </ul>
                                                    <div className="dropdown-btn"><span className="fas fa-angle-down"></span></div>
                                                </li>
                                                <li><Link to="/Products/Details/Awning">Сенници</Link></li>
                                                <li><Link to="/Products/Details/Nets">Комарници</Link></li>
                                            </ul>
                                            <div className="dropdown-btn"><span className="fas fa-angle-down"></span></div>
                                        </li>
                                        <li><Link to="/Calculator" onClick={refreshPage}>Калкулатор</Link></li>
                                        <li onClick={refreshPage}><Link to="/Gallery">Галерия</Link></li>
                                        <li><Link to="/Contact">Контакти</Link></li>
                                    </ul>
                                </div>
                            </nav>
                            <ul className="menu-right-content clearfix">

                                <li className="support-box">
                                    <i className="icon-phone"></i>
                                    <p>Безплатен размер</p>
                                    <h3><Link to="tel:+359878770402">0878 77 04 02</Link></h3>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </header>
            <div className="mobile-menu">
                <div className="menu-backdrop"></div>
                <div className="close-btn"><i className="fas fa-times"></i></div>

                <nav className="menu-box">
                    <div className="nav-logo" onClick={refreshPage}><Link to="/"><img src="/images/logo.png" alt="" title="" /></Link></div>
                    <div className="menu-outer">
                        <div className="collapse navbar-collapse show clearfix custom" id="navbarSupportedContent">
                            <ul className="navigation clearfix">
                                <li onClick={refreshPage}><Link to="/">Начало</Link></li>
                                <li className="dropdown"><Link to="/Catalogs" className="close-mobile-menu">Цени</Link>
                                    <ul >
                                        <li className="dropdown"><Link to="/Catalogs" className="close-mobile-menu">Щори</Link>
                                            <ul>
                                                <li onClick={refreshPage}><Link to="/Catalogs/Prices/Horizontal">Хоризонтални</Link></li>
                                                <li onClick={refreshPage}><Link to="/Catalogs/Prices/Vertical">Вертикални</Link></li>
                                                <li onClick={refreshPage}><Link to="/Catalogs/Prices/Wooden">Дървени</Link></li>
                                                <li onClick={refreshPage}><Link to="/Catalogs/Prices/Roller">Руло</Link></li>
                                                <li onClick={refreshPage}><Link to="/Catalogs/Prices/Roman">Римски</Link></li>
                                                <li onClick={refreshPage}><Link to="/Catalogs/Prices/Pleat">Плисе</Link></li>
                                                <li onClick={refreshPage}><Link to="/Catalogs/Prices/Bamboo">Бамбукови</Link></li>
                                                <li onClick={refreshPage}><Link to="/Catalogs/Prices/ExternalRoller">Външни ролетни</Link></li>
                                                <li onClick={refreshPage}><Link to="/Catalogs/Prices/ExternalVenetian">Външни ламелни</Link></li>
                                            </ul>
                                            <div className="dropdown-btn"><span className="fas fa-angle-down"></span></div>
                                        </li>
                                        <li onClick={refreshPage}><Link to="/Catalogs/Prices/Awning">Сенници</Link></li>
                                        <li onClick={refreshPage}><Link to="/Catalogs/Prices/Nets">Комарници</Link></li>
                                    </ul>
                                    <div className="dropdown-btn"><span className="fas fa-angle-down"></span></div>
                                </li>
                                <li className="dropdown"><Link to="/Colors" className="close-mobile-menu">Цветове</Link>
                                    <ul>
                                        <li className="dropdown"><Link to="/Colors" className="close-mobile-menu">Щори</Link>
                                            <ul>
                                                <li onClick={refreshPage}><Link to="/Colors/Samples/Horizontal">Хоризонтални</Link></li>
                                                <li onClick={refreshPage}><Link to="/Colors/Samples/Vertical">Вертикални</Link></li>
                                                <li onClick={refreshPage}><Link to="/Colors/Samples/Wooden">Дървени</Link></li>
                                                <li onClick={refreshPage}><Link to="/Colors/Samples/Roller">Руло</Link></li>
                                                <li onClick={refreshPage}><Link to="/Colors/Samples/Roman">Римски</Link></li>
                                                <li onClick={refreshPage}><Link to="/Colors/Samples/Pleat">Плисе</Link></li>
                                                <li onClick={refreshPage}><Link to="/Colors/Samples/Bamboo">Бамбукови</Link></li>
                                                <li><Link to="/Colors/Samples/ExternalRoller">Външни ролетни</Link></li>
                                                <li><Link to="/Colors/Samples/ExternalVenetian">Външни ламелни</Link></li>
                                            </ul>
                                            <div className="dropdown-btn"><span className="fas fa-angle-down"></span></div>
                                        </li>
                                        <li onClick={refreshPage}><Link to="/Colors/Samples/Awning">Сенници</Link></li>
                                        <li><Link to="/Colors/Samples/Nets">Комарници</Link></li>
                                    </ul>
                                    <div className="dropdown-btn"><span className="fas fa-angle-down"></span></div>
                                </li>
                                <li className="dropdown"><Link to="/Products" className="close-mobile-menu">Продукти</Link>
                                    <ul>
                                        <li className="dropdown"><Link to="/Products" className="close-mobile-menu">Щори</Link>
                                            <ul>
                                                <li className="close-mobile-menu"><Link to="/Products/Details/Horizontal">Хоризонтални</Link></li>
                                                <li className="close-mobile-menu"><Link to="/Products/Details/Vertical">Вертикални</Link></li>
                                                <li className="close-mobile-menu"><Link to="/Products/Details/Wooden">Дървени</Link></li>
                                                <li className="close-mobile-menu"><Link to="/Products/Details/Roller">Руло</Link></li>
                                                <li className="close-mobile-menu"><Link to="/Products/Details/Roman">Римски</Link></li>
                                                <li className="close-mobile-menu"><Link to="/Products/Details/Pleat">Плисе</Link></li>
                                                <li className="close-mobile-menu"><Link to="/Products/Details/Bamboo">Бамбукови</Link></li>
                                                <li className="close-mobile-menu"><Link to="/Products/Details/ExternalRoller">Външни ролетни</Link></li>
                                                <li className="close-mobile-menu"><Link to="/Products/Details/ExternalVenetian">Външни ламелни</Link></li>
                                            </ul>
                                            <div className="dropdown-btn"><span className="fas fa-angle-down"></span></div>
                                        </li>
                                        <li className="close-mobile-menu"><Link to="/Products/Details/Awning">Сенници</Link></li>
                                        <li className="close-mobile-menu"><Link to="/Products/Details/Nets">Комарници</Link></li>
                                    </ul>
                                    <div className="dropdown-btn"><span className="fas fa-angle-down"></span></div>
                                </li>
                                <li onClick={refreshPage} className="close-mobile-menu"><Link to="/Calculator">Калкулатор</Link></li>
                                <li className="close-mobile-menu"  onClick={refreshPage}><Link to="/Gallery">Галерия</Link></li>
                                <li className="close-mobile-menu"><Link to="/Contact">Контакти</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="contact-info">
                        <h4>Contact Info</h4>
                        <ul>
                            <li className="close-mobile-menu">София</li>
                            <li className="close-mobile-menu"><span>Имейл: </span><a href="mailto:info@claudi.bg">info@claudi.bg</a></li>
                            <li className="close-mobile-menu"><span>Телефон: </span><a href="tel:+359878770402">0878 77 04 02</a></li>
                        </ul>
                    </div>
                    <div className="social-links">
                    </div>
                </nav>
            </div>
        </>
    );
}; 