import { useEffect } from "react";

import styles from './gallery.module.css';

export const Picture = ({ group }) => {

    useEffect(() => {
    }, []);

    return (
        <>
            <div className={`col-lg-3 col-md-6 col-sm-12 masonry-item small-column all shutters ${group.cssClass}`}>
                <div className="project-block-one">
                    <div className="inner-box">
                        <a href={process.env.PUBLIC_URL + `/storage/gallery/${group.folder}/${group.color}`} className="lightbox-image" data-fancybox="gallery">
                            <figure className="image-box">
                                <img src={process.env.PUBLIC_URL + `/storage/gallery/${group.folder}/${group.color}`} />
                            </figure>
                            <div className="content-box">
                                {/* <div className="view-btn"><a href={process.env.PUBLIC_URL + `/storage/galley/${group.folder}/${group.color}`} className="lightbox-image" data-fancybox="gallery">+</a></div> */}
                                {/* <div className="text"> */}
                                <h3 className={`purpleColor ${styles.h3}`}>{group.title}</h3>
                                {/* </div> */}
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
};