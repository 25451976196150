import './App.css';

import ReactGA from "react-ga"

import { Routes, Route } from "react-router-dom";


import { Header } from "./components/header/Header";
import { Footer } from "./components/footer/Footer";
import { Home } from "./components/home/Home";
import { Error404 } from "./components/home/Error404";
import { Cookies } from "./components/terms-and-privacy/Cookies";
import { Privacy } from "./components/terms-and-privacy/Privacy";
import { Contact } from "./components/home/contact/Contact";
import { Calculator } from "./components/calculator/Calculator";
import { Products } from "./components/products/Products";
import { Catalogs } from "./components/catalogs/Catalogs";
import { Colors } from "./components/colors/Colors";
import { Details } from "./components/products/Details";
import { Prices } from "./components/catalogs/Prices";
import { Samples } from "./components/colors/Samples";
import { Gallery } from "./components/gallery/Gallery";

const TRACKING_ID = "G-J7X3L3MEN0";
ReactGA.initialize(TRACKING_ID);

function App() {
    return (
        <div className="boxed_wrapper">
            <Header />
            <Routes>
                <Route path="/" element={<Home />} />

                <Route path="/Cookies" element={<Cookies />} />
                <Route path="/Privacy" element={<Privacy />} />
                <Route path="/Contact" element={<Contact />} />
                <Route path="/Calculator" element={<Calculator />} />
                <Route path="/Products" element={<Products />} />
                <Route path="/Catalogs" element={<Catalogs />} />
                <Route path="/Colors" element={<Colors />} />
                <Route path="/Gallery" element={<Gallery />} />
                <Route path="/Products/Details/:productId" element={<Details />} />
                <Route path="/Catalogs/Prices/:catalogId" element={<Prices />} />
                <Route path="/Colors/Samples/:modelId" element={<Samples />} />

                <Route path="/*" element={<Error404 />} />
            </Routes>
            <Footer />
        </div>
    );
}

export default App;
