import {useEffect} from 'react';

export const Cookies = () => {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }, []);
    return (
        <>
            <div className="container">
                <div className="static-content contacts left w100">
                    <h1>Политика за бисквитки</h1>


                    <div className="left-part-page">
                        <p>Настоящата политика има за цел да Ви информира защо използваме „бисквитките“, какво правят те и какви избори имате във връзка с употребата им.</p>

                        <h4 className="mt-6 uppercase font-bold text-gray-800 dark:text-zinc-300">Какво представляват бисквитките?</h4>

                        <p className="mt-4">Бисквитките са малки текстови файлове, които се генерират при заявка от Вашия браузър към наш web сървър и се съхраняват на устройството Ви. Обикновено целта им е да можем да идентифицираме Вашето устройство и поведението му при посещение на сайтовете ни.</p>

                        <p className="mt-4">Има два вида бисквитки – постоянни и временни (сесийни бисквитки).</p>

                        <p className="mt-4">Сесийните бисквитки се съхраняват временно в компютъра Ви, когато посещавате нашия Сайт, но се изтриват в момента, в който затворите страницата.</p>

                        <p className="mt-4">Постоянните бисквитки се съхраняват като файл на вашия компютър или мобилно устройство за по-дълъг период от време.</p>

                        <p className="mt-4">С изключение на бисквитките, необходими за функционирането на нашия сайт, съхраняваме бисквитки на устройството Ви единствено с Ваше съгласие. Ако не искате да приемате бисквитки, можете да коригирате настройките на браузъра си – повече информация за това ще намерите в края на този документ.</p>

                        <p className="mt-4">Ако предпочитате да не получавате „бисквитки“ докато разглеждате уебсайта ни, бихте могли да ги откажете, като настроите интернет браузъра си да предупреждава преди да приеме бисквитка или да откажете бисквитки, когато интернет браузъра Ви сигнализира за наличието им. Също така, бихте могли да откажете всички бисквитки, като ги изключите чрез настройките на Вашия интернет браузър. Имайте предвид, че забраняването на бисквитки ще се отрази на функционалността на този и много други уебсайтове, които посещавате. Деактивираните бисквитки обикновено ще доведат също до деактивиране на определени функции на уебсайта ни.</p>

                        <h4 className="mt-6 uppercase font-bold text-gray-800 dark:text-zinc-300">Как се изтриват бисквитки?</h4>

                        <p className="mt-4">Вие знаете кога и дали Вашият браузър получава бисквитки, тъй като той Ви уведомява при първо посещение за това. По този начин имате възможност да приемете или откажете дадена бисквитка.</p>

                        <p className="mt-4">Браузърът Ви може да бъде настроен да отхвърля всички бисквитки. Ако искате да разберете как да направите това, посетете <a href="http://www.allaboutcookies.org/">allaboutcookies.org</a> или Наръчника за бисквитки на <a href="https://support.microsoft.com/en-us/help/260971/description-of-cookies">Microsoft</a>.</p>

                        <p className="mt-4">Обърнете внимание, че промените в браузъра Ви, с които се деактивира функцията за бисквитки, могат да попречат на сайта да функционира правилно.</p>

                        <p className="mt-4">Може да изберете какви бисквитки да позволите или забраните, като посетите съответните сайтове за настройки на вашите браузъри:</p>

                        <p>Настройки за бисквитки на <a href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies#ie=ie-10">Internet Explorer</a></p>

                        <p>Настройки за бисквитки на <a href="https://support.google.com/chrome/answer/95647?hl=en&amp;ref_topic=14666">Chrome</a></p>

                        <p>Настройки за бисквитки на <a href="https://support.apple.com/kb/PH17191?locale=en_US"></a> и iOS <a href="https://support.apple.com/en-us/HT201265">Safari web</a>.</p>

                        <h4 className="mt-6 uppercase font-bold text-gray-800 dark:text-zinc-300">Абсолютно необходими бисквитки</h4>

                        <p className="mt-4">Определени "бисквитки" са необходими, за да можем сигурно да предоставим нашите услуги чрез уеб-страницата. Тази категория включва, напр.:</p>

                        <p>– "Бисквитки", които идентифицират или удостоверяват нашите потребители;</p>

                        <p>– "Бисквитки", които временно съхраняват определени потребителски данни (например съдържание на онлайн формуляр);</p>

                        <p>– "Бисквитки", които съхраняват определени потребителски предпочитания (напр. настройки за търсене или настройки за език);</p>

                        <p>– "Бисквитки", които съхраняват данни, за да осигурят безпрепятствено възпроизвеждане на видео или аудио съдържание.</p>

                        <h4 className="mt-6 uppercase font-bold text-gray-800 dark:text-zinc-300">Аналитични „бисквитки”</h4>

                        <p className="mt-4">Използваме аналитични "бисквитки", за да регистрираме поведението на потребителите (напр. кликване върху банери на рекламите, въведени заявки за търсене) и да оценим статистически тези действия.</p>

                        <h4 className="mt-6 uppercase font-bold text-gray-800 dark:text-zinc-300">Рекламни „бисквитки”</h4>

                        <p className="mt-4">Ние също използваме "бисквитки" за рекламни цели. Профилите на поведение на потребителите, създадени с помощта на такива "бисквитки" (напр. кликване върху рекламни банери, посетени подстраници, въведени заявки за търсене) се използват от нас, за да Ви показваме реклами или оферти, които са съобразени с Вашите интереси ("реклама, базирана на индивидуалния интерес").</p>

                        <h4 className="mt-6 uppercase font-bold text-gray-800 dark:text-zinc-300">Рекламни "бисквитки" на трети страни</h4>

                        <p className="mt-4">Ние също така позволяваме на други компании да оценяват данните на нашите потребители чрез рекламни "бисквитки". Това позволява на нас и на трети страни да показваме на потребителите на нашата уеб-страница реклами, основаващи се на интереси, които се основават на анализ на вашето поведение на ползване (напр. кликвания върху рекламни банери, посетени подстраници, въведени заявки), като това не се ограничава само до нашите онлайн оферти.</p>

                        <h4 className="mt-6 uppercase font-bold text-gray-800 dark:text-zinc-300">"Бисквитки" при препращане</h4>

                        <p className="mt-4">Нашите партньори за проследяване на препращания задават "бисквитки" на компютъра Ви ("бисквитка при препращане"), в случай че сте достигнали нашия уеб сайт чрез реклама на съответния партньор. Тези "бисквитки" обикновено губят валидността си след 30 дни. В случай, че посетите определени страници, които хостваме, и че "бисквитката" все още не е изтекла, ние и съответният партньор за проследяване на реализациите може да видим, че конкретен потребител е кликнал върху рекламата и е препратен на страницата ни. Информацията, събрана чрез "бисквитката" за препращане, се използва за създаване на статистически данни за препращанията и за определяне на общия брой потребители, които са кликнали върху съответната реклама и са препратени към страница, която е снабдена с маркер за проследяване на препращанията.</p>

                        <h4 className="mt-6 uppercase font-bold text-gray-800 dark:text-zinc-300">Анализ на уеб пространството</h4>

                        <p className="mt-4">Нуждаем се от статистическа информация за използването на нашата уеб-страница, за да я направим по-достъпна, да извършваме измервания на обхвата и да правим пазарни проучвания.</p>

                        <p className="mt-4">За тази цел използваме инструментите за уеб анализ, описани в този раздел.</p>

                        <p className="mt-4">Потребителските профили, създадени от тези инструменти, с помощта на аналитични бисквитки или чрез оценка на лог файловете, не съдържат лични данни. Инструментите или изобщо не използват IP адресите на потребителите, или ги съкращават незабавно след събирането им.</p>

                        <p className="mt-4">Доставчиците на инструментите обработват данните само като обработващи личните данни според нашите указания, а не за собствени цели.</p>

                        <p className="mt-4">По-долу ще намерите информация за всеки доставчик на инструмент и как можете да възразите срещу събирането и обработването на лични данни чрез инструмента.</p>

                        <p className="mt-4">Следва да имате предвид, че при инструментите, които използват бисквитки за отказ, функцията за отказ е свързана с устройството или браузъра и, следователно, е валидна за използваното в момента крайно устройство или браузър. Ако използвате няколко крайни устройства или браузъра, трябва да изберете функцията за отказ на всяко крайно устройство и във всеки използван браузър.</p>

                        <p className="mt-4">Освен това, като цяло можете да предотвратите създаването на потребителски профили, като деактивирате използването на бисквитки.</p>

                        <h4 className="mt-6 uppercase font-bold text-gray-800 dark:text-zinc-300">Google Analytics</h4>

                        <p className="mt-4">Google Analytics се предлага от Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA ("Google"). Ползваме Google Analytics с предлаганата от Google допълнителна функция за анонимизиране на IP адресите. Google съкращава IP адреса още в рамките на ЕС и само в изключителни случаи в САЩ, като и в двата случая записва само съкратени IP адреси.</p>

                        <p className="mt-4">Можете да възразите срещу събирането и обработването на данните ви, като изтеглите и инсталирате приставка за браузър от следния <a href="http://tools.google.com/dlpage/gaoptout?hl=en">линк</a></p>
                    </div>
                </div>
            </div>
        </>
    );
};