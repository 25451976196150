export const Sample = ({ group }) => {
    return (
        <>
            <div className={`col-lg-3 col-md-6 col-sm-12 masonry-item small-column all shutters ${group.cssClass}`}>
                {/* <a href={process.env.PUBLIC_URL + `/storage/samples/${group.product}/${group.folder}/${group.color}`} className="lightbox-image" data-fancybox="gallery"> */}
                <div className="project-block-one">
                    <div className="inner-box">
                        <figure className="image-box">
                            <img src={process.env.PUBLIC_URL + `/storage/samples/${group.product}/${group.folder}/${group.color}`} />
                        </figure>
                        <div className="content-box">
                            {/* <div className="view-btn"><a href={process.env.PUBLIC_URL + `/storage/samples/${group.product}/${group.folder}/${group.color}`} className="lightbox-image" data-fancybox="gallery">+</a></div> */}
                            <div className="text">
                                <div class="view-btn">
                                    <a href={process.env.PUBLIC_URL + `/storage/samples/${group.product}/${group.folder}/${group.color}`} className="lightbox-image" data-fancybox="gallery">
                                    {group.color.substring(0, group.color.indexOf('.'))}
                                    </a>
                                </div>
                                <h3 className="purpleColor">
                                    {group.folder.substring(group.folder.indexOf('_') + 1).toUpperCase()}
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
                {/* </a> */}
            </div>
        </>
    );
};